<template>
    <div class="builder-block" :class="spacerValue()">
        <div class="inner inner--small">
            <div class="builder-block__image">
                <img
                    :src="block.fields['block_image__image'][0].value.temporary_url"
                    :alt="block.fields['block_image__image'][0].value.alt_tag"
                    />
            </div>
        </div>
    </div>
</template>

<script>
    
    export default {
        name: "BlockImage",
        props: {
            block: {
                type: Object,
                required: true
            }
        },
        methods: { 
            spacerValue() {
                var spacer = this.block.fields['block_image__spacer'][0].value || '';
                if(spacer === 'xsmall') return 'mb-18'
                if(spacer === 'small') return 'mb-32'
                if(spacer === 'medium') return 'mb-54'
                if(spacer === 'large') return 'mb-80'
                if(spacer === 'xlarge') return 'mb-120'
            },
        }

    }

</script>
